let textElements = document.querySelectorAll(".fade-text")
let imageElements = document.querySelectorAll(".image-animate")

function animateElements(elements) {
    setTimeout(function() {
        function animate() {
            for(let i = 0; i < elements.length; i++) {
                let fadeElem = elements[i]

                if (isElementInViewport(fadeElem) && !fadeElem.classList.contains("fade-show")) {
                    fadeElem.classList.add("fade-show")
                }
            }
        }

        animate()
        window.addEventListener("scroll", animate)
    }, 500)
}

if(textElements.length > 0) {
    animateElements(textElements)
}

if(imageElements.length > 0) {
    animateElements(imageElements)
}

// check if an element is in viewport
function isElementInViewport(el) {
    let rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        //calculate from the top + 10% of element
        rect.top + (rect.height*0.15) <= (window.innerHeight || document.documentElement.clientHeight)
    );
}


